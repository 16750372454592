import * as React from "react"
import Layout from "../components/layout";
import {Avatar, Box, Button, Container, Grid, Typography} from "@mui/material";
import Hero from "../components/hero";

import exampleWeb from "../images/example-web.png";
import demographics from "../images/demographics.png";
import downloadFromAppStore from "../images/download-from-app-store.png";

import mLamboPhoto from "../images/reviewer/m-lambo.png";
import mWerksmanPhoto from "../images/reviewer/m-werksman.png";
import dMillerPhoto from "../images/reviewer/d-miller.png";
import pBeasleyPhoto from "../images/reviewer/p-beasley.png";
import pLorPhoto from "../images/reviewer/p-lor.png";


const regularRowStyle = {
    padding: '2em 1em'
};

const coloredRowStyle = {
    ...regularRowStyle,
    backgroundColor: "#E7ECF1"
};

const HighlightRow = ({children, ...props}) => {
    return (
        <div {...props}>
            <Container>
                <Grid container spacing={2} justifyContent={"space-between"}>
                    {children}
                </Grid>
            </Container>
        </div>
    );
};

// markup
const IndexPage = () => {
    return (
        <Layout title={"Jurybox"}>
            <main>
                <Hero title={"Select a Better Jury"}
                      subtitle={"Best in class jury selection software to help you select a better jury and win more cases"}/>

                <Grid container justifyContent={"center"} alignItems={"center"}
                      style={coloredRowStyle}>
                    <Grid item padding={1}>
                        <Button variant={"contained"} href="https://app.juryboxapp.com/signup" size={"large"}>Try free 3
                            day evaluation</Button>
                    </Grid>
                    <Grid item padding={1}>
                        <Button variant={"outlined"} style={{backgroundColor: 'white'}} target="_blank" href="https://youtu.be/FsyEKIyCdd8" size={"small"}>Watch the demo</Button>
                    </Grid>
                </Grid>

                <HighlightRow style={regularRowStyle}>
                    <Grid item md={4}>
                        <Typography variant={"h5"} color={"primary"}>Digital Sticky Notes</Typography>
                        <Typography variant={"body1"} color={"secondary"}>Record notes and graphical impressions about
                            jurors while the system tracks peremptory and for-cause challenges
                            automatically</Typography>

                        <Typography variant={"h5"} color={"primary"} marginTop={2}>Realtime collaboration</Typography>
                        <Typography variant={"body1"} color={"secondary"}>Prepare for your trial with your team and have
                            an assistant take notes for you while you focus on the jury</Typography>

                        <Typography variant={"h5"} color={"primary"} marginTop={2}>Questionnaire data</Typography>
                        <Typography variant={"body1"} color={"secondary"}>Easily manage large amounts of data gathered
                            from questionnaires and reference it during the trial</Typography>
                    </Grid>
                    <Grid item md={8}>
                        <img src={exampleWeb} alt={"Example of user interface on web application"}
                             style={{maxWidth: '100%'}}/>
                    </Grid>
                </HighlightRow>

                <HighlightRow style={coloredRowStyle}>
                    <Grid item md={8}>
                        <img src={demographics} alt={"Example of user interface on web application"}
                             style={{maxWidth: '100%'}}/>
                    </Grid>
                    <Grid item md={4} padding={1}>
                        <Typography variant={"h5"} color={"primary"}>Demographics Analysis</Typography>
                        <Typography variant={"body1"} color={"secondary"}>Visualize jury demographics breakdown and
                            compare to peremptory challenge demographics made by prosecution and defense to quickly spot
                            or avoid unfair bias</Typography>

                        <Typography variant={"h5"} color={"primary"} marginTop={2}>Fully customized
                            tracking</Typography>
                        <Typography variant={"body1"} color={"secondary"}>Easily track any jury demographic with fully
                            customized selection categories</Typography>
                    </Grid>
                </HighlightRow>
                <HighlightRow style={regularRowStyle}>
                    <Grid item md={3} padding={1}>
                        <Typography variant={"h5"} color={"primary"}>
                            Trying to keep up with the fast pace of jury
                            selection?
                        </Typography>
                        <Typography variant={"body1"} color={"secondary"} marginTop={2}>
                            Jurybox can help you stay organized and
                            effective so you can focus on selecting a great jury
                        </Typography>

                        <Typography variant={"body1"} color={"secondary"} marginTop={1}>
                            Type notes instead of juggling sticky notes,
                            or let your assistant take notes for you and get real time updates
                        </Typography>

                        <Typography variant={"body1"} color={"secondary"} marginTop={1}>
                            Use on a laptop or iPad and reference data
                            stored securely in the Cloud
                        </Typography>
                    </Grid>
                    <Grid item md={8} justifyContent={'center'} id="jurybox-overview-video-container">
                        <iframe id={"jurybox-overview-video"} src="https://www.youtube.com/embed/4gWkTiXDAVc"
                                title="Jurybox overview" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </Grid>
                </HighlightRow>

                <HighlightRow style={coloredRowStyle}>
                    <Typography variant={"h5"}
                                marginBottom={3}
                                color={"primary"}>
                        Here's what trial attorneys are saying about Jurybox:
                    </Typography>

                    <Box display={"flex"} flexDirection={"row"} alignItems={'center'} marginTop={2}>
                        <Box paddingRight={'1em'}>
                            <Avatar src={mLamboPhoto} alt={"photo of Michelle Lambo"}/>
                        </Box>
                        <Box>
                            <strong>Thank you for inventing Jurybox. I love this software!</strong>&nbsp;
                            It truly is the most ingenious trial software. It is so user friendly and easy to use.
                            <div>
                                <em> - Michelle Lambo, President of Hillsborough County Association of Criminal Defense
                                    Lawyers</em>
                            </div>
                        </Box>
                    </Box>

                    <Box display={"flex"} flexDirection={"row"} alignItems={'center'} marginTop={2}>
                        <Box paddingRight={'1em'}>
                            <Avatar src={mWerksmanPhoto} alt={"photo of Mark Werksman"}/>
                        </Box>
                        <Box>
                            Jurybox is simple to use, clear and helpful; <strong>honestly a gamechanger... no more sticky notes. I love it!</strong>
                            <div>
                                <em> - Mark Werksman, Managing Partner at Werksman Jackson & Quinn</em>
                            </div>
                        </Box>
                    </Box>

                    <Box display={"flex"} flexDirection={"row"} alignItems={'center'} marginTop={2}>
                        <Box paddingRight={'1em'}>
                            <Avatar src={dMillerPhoto} alt={"photo of Deborah Miller"}/>
                        </Box>
                        <Box>
                            <strong>Jurybox is exactly what I have been looking for. </strong>
                            If I knew how to design an app, it would have been this. It’s features are user friendly
                            and incorporates the “sticky note” system on an app. I highly recommend this app to all
                            trial attorneys.
                            <div>
                                <em> - Deborah Miller, Senior Deputy District Attorney at Fresno County District Attorney's Office</em>
                            </div>
                        </Box>
                    </Box>

                    <Box display={"flex"} flexDirection={"row"} alignItems={'center'} marginTop={2}>
                        <Box paddingRight={'1em'}>
                            <Avatar src={pBeasleyPhoto} alt={"photo of Patrick Beasley"}/>
                        </Box>
                        <Box>
                            <strong>Jurybox streamlined my jury selection process tremendously.</strong> I have been thoroughly impressed by the responsiveness of the Jurybox staff. Their willingness to listen and accommodate is awesome.
                            <div>
                                <em> - Patrick Beasley, Assistant District Attorney at Sixth Judicial District Court in Jackson, MS</em>
                            </div>
                        </Box>
                    </Box>

                    <Box display={"flex"} flexDirection={"row"} alignItems={'center'} marginTop={2}>
                        <Box paddingRight={'1em'}>
                            <Avatar src={pLorPhoto} alt={"photo of Pahoua Lor"}/>
                        </Box>
                        <Box>
                            I love Jurybox for its simplicity. <strong>It automates the process I would naturally use for jury
                            selection.</strong> It's really nice that my assistant can take notes for me during a case and they
                            instantly pop up on my laptop. Jurybox allows me to focus on the jurors' responses instead
                            of moving around sticky notes.
                            <div>
                                <em> - Pahoua Lor, Owner of Law Offices of Pahoua C Lor</em>
                            </div>
                        </Box>
                    </Box>

                </HighlightRow>
                <Hero title={"Pricing"}
                      id={"pricing"}
                      subtitle={"At Jurybox we keep things simple, and that includes pricing"} />

                <Container style={{textAlign: 'center', paddingTop: '4em', paddingBottom: '4em'}}>
                    <Typography variant={"h5"} color={"primary"}>Unlimited trials for one low annual price</Typography>
                    <Typography variant={"h4"} color={"primary"} marginBottom={2}>$399/year per user</Typography>
                    <Typography variant={"subtitle1"}>Discounts available for public sector and offices with 5+ users</Typography>
                    <Typography variant={"body2"}>Contact <strong>support@juryboxapp.com</strong> for more information</Typography>

                    <Grid container spacing={2} alignItems={'center'} justifyContent={'center'} marginTop={4}>
                        <Grid item md={3} xs={12}>
                            <Button variant={"contained"} href="https://app.juryboxapp.com/signup" size={"large"}>Try
                                free 3
                                day evaluation</Button>
                            <Typography variant={"subtitle2"}>No credit card required</Typography>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Button variant={"outlined"} target="_blank" href="https://youtu.be/FsyEKIyCdd8" size={"small"}
                                    style={{backgroundColor: 'white'}}>Watch the demo</Button>
                            <Typography variant={"subtitle2"}>See our software in action</Typography>
                        </Grid>
                    </Grid>
                </Container>
                <HighlightRow style={coloredRowStyle}>
                    <Grid item width={"100%"}>
                        <Typography variant={"h5"} textAlign={"center"}>Also try the iPad app for free after signing up online</Typography>
                    </Grid>
                    <Grid item width={"100%"}>
                        <Grid container justifyContent={'center'} alignItems={"center"}>
                        <a href="https://apps.apple.com/us/app/jurybox-jury-selection/id1514195436">
                            <img src={downloadFromAppStore} alt={"Download from App Store"} height={50} />
                        </a>
                        </Grid>
                    </Grid>
                </HighlightRow>
            </main>
        </Layout>
    )
}

export default IndexPage
